import React, { useState } from "react";
import "./ExampleSidebar.scss";

export default function Sidebar({children}: { children: React.ReactNode }) {
    const [open, setOpen] = useState(false);

    return (
        <>
            <div id="mySidebar" className={`sidebar ${open ? "open" : ""}`}>
                <button className="closebtn" onClick={() => setOpen(false)}>
                    x
                </button>
                <div className="sidebar-links">
                    <button>Dummy Home</button>
                    <button>Dummy About</button>
                    {" "}
                </div>
            </div>
            <div className={`${open ? "sidebar-open" : ""}`}>
                {children}
            </div>
        </>
    );
}
