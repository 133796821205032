// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
  height: 100%;
  width: 0;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}
.sidebar.open {
  width: 300px;
}

.sidebar a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidebar a:hover {
  color: #f1f1f1;
}

.sidebar .closebtn {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 36px;
  margin-left: 50px;
}

.openbtn {
  font-size: 20px;
  cursor: pointer;
  background-color: #111;
  color: white;
  padding: 10px 15px;
  border: none;
  display: flex;
  margin-left: 50px;
}

.sidebar-open {
  margin-left: 300px;
}`, "",{"version":3,"sources":["webpack://./src/sidebar/ExampleSidebar.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,QAAA;EACA,kBAAA;EACA,UAAA;EACA,MAAA;EACA,OAAA;EACA,sBAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AACF;AACE;EACE,YAAA;AACJ;;AAIA;EACE,yBAAA;EACA,qBAAA;EACA,eAAA;EACA,cAAA;EACA,cAAA;EACA,gBAAA;AADF;;AAIA;EACE,cAAA;AADF;;AAIA;EACE,kBAAA;EACA,MAAA;EACA,QAAA;EACA,eAAA;EACA,iBAAA;AADF;;AAIA;EACE,eAAA;EACA,eAAA;EACA,sBAAA;EACA,YAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,iBAAA;AADF;;AAGA;EACE,kBAAA;AAAF","sourcesContent":[".sidebar {\n  height: 100%;\n  width: 0;\n  position: absolute;\n  z-index: 1;\n  top: 0;\n  left: 0;\n  background-color: #111;\n  overflow-x: hidden;\n  transition: 0.5s;\n  padding-top: 60px;\n\n  &.open {\n    width: 300px;\n  }\n\n}\n\n.sidebar a {\n  padding: 8px 8px 8px 32px;\n  text-decoration: none;\n  font-size: 25px;\n  color: #818181;\n  display: block;\n  transition: 0.3s;\n}\n\n.sidebar a:hover {\n  color: #f1f1f1;\n}\n\n.sidebar .closebtn {\n  position: absolute;\n  top: 0;\n  right: 0;\n  font-size: 36px;\n  margin-left: 50px;\n}\n\n.openbtn {\n  font-size: 20px;\n  cursor: pointer;\n  background-color: #111;\n  color: white;\n  padding: 10px 15px;\n  border: none;\n  display: flex;\n  margin-left: 50px;\n}\n.sidebar-open {\n  margin-left: 300px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
