// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  font-family: sans-serif;
  text-align: center;
}
.App .comment-avatar {
  background: #1042da;
  border-radius: 66px 67px 67px 0px;
  width: 2rem;
  color: white;
  height: 2rem;
  padding: 4px;
  margin: 4px;
}
.App .comment-avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.button-wrapper button {
  z-index: 1;
  height: 40px;
  max-width: 200px;
  margin: 10px;
  padding: 5px;
}

.excalidraw .App-menu_top .buttonList {
  display: flex;
}

.excalidraw-wrapper {
  height: 100dvh;
  position: relative;
  overflow: hidden;
}

:root[dir=ltr] .excalidraw .layer-ui__wrapper .zen-mode-transition.App-menu_bottom--transition-left {
  transform: none;
}

.excalidraw .panelColumn {
  text-align: left;
}

.export-wrapper {
  display: flex;
  flex-direction: column;
  margin: 50px;
}
.export-wrapper__checkbox {
  display: flex;
}

.excalidraw {
  --color-primary: #4f29f3;
  --color-primary-darker: #4f29f3;
  --color-primary-darkest: #4f29f3;
  --color-primary-light: #4f29f3;
}
.excalidraw button.custom-element {
  width: 2rem;
  height: 2rem;
  margin: 0 8px;
}
.excalidraw .custom-footer,
.excalidraw .custom-element {
  padding: 0.1rem;
}

.excalidraw .HelpDialog__key {
  color: white !important;
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAAA;EACC,uBAAA;EACA,kBAAA;AACD;AACC;EACC,mBAAA;EACA,iCAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;EACA,YAAA;EACA,WAAA;AACF;AAAE;EACC,WAAA;EACA,YAAA;EACA,kBAAA;AAEH;;AAGA;EACC,UAAA;EACA,YAAA;EACA,gBAAA;EACA,YAAA;EACA,YAAA;AAAD;;AAGA;EACC,aAAA;AAAD;;AAGA;EACC,cAAA;EACA,kBAAA;EACA,gBAAA;AAAD;;AAGA;EAIC,eAAA;AAHD;;AAMA;EACC,gBAAA;AAHD;;AAMA;EACC,aAAA;EACA,sBAAA;EACA,YAAA;AAHD;AAKC;EACC,aAAA;AAHF;;AAOA;EACC,wBAAA;EACA,+BAAA;EACA,gCAAA;EACA,8BAAA;AAJD;AAMC;EACC,WAAA;EACA,YAAA;EACA,aAAA;AAJF;AAOC;;EAEC,eAAA;AALF;;AAQA;EACC,uBAAA;AALD","sourcesContent":[".App {\n\tfont-family: sans-serif;\n\ttext-align: center;\n\n\t.comment-avatar {\n\t\tbackground: #1042da;\n\t\tborder-radius: 66px 67px 67px 0px;\n\t\twidth: 2rem;\n\t\tcolor: #ffff;\n\t\theight: 2rem;\n\t\tpadding: 4px;\n\t\tmargin: 4px;\n\t\timg {\n\t\t\twidth: 100%;\n\t\t\theight: 100%;\n\t\t\tborder-radius: 50%;\n\t\t}\n\t}\n}\n\n.button-wrapper button {\n\tz-index: 1;\n\theight: 40px;\n\tmax-width: 200px;\n\tmargin: 10px;\n\tpadding: 5px;\n}\n\n.excalidraw .App-menu_top .buttonList {\n\tdisplay: flex;\n}\n\n.excalidraw-wrapper {\n\theight: 100dvh;\n\tposition: relative;\n\toverflow: hidden;\n}\n\n:root[dir='ltr']\n\t.excalidraw\n\t.layer-ui__wrapper\n\t.zen-mode-transition.App-menu_bottom--transition-left {\n\ttransform: none;\n}\n\n.excalidraw .panelColumn {\n\ttext-align: left;\n}\n\n.export-wrapper {\n\tdisplay: flex;\n\tflex-direction: column;\n\tmargin: 50px;\n\n\t&__checkbox {\n\t\tdisplay: flex;\n\t}\n}\n\n.excalidraw {\n\t--color-primary: #4f29f3;\n\t--color-primary-darker: #4f29f3;\n\t--color-primary-darkest: #4f29f3;\n\t--color-primary-light: #4f29f3;\n\n\tbutton.custom-element {\n\t\twidth: 2rem;\n\t\theight: 2rem;\n\t\tmargin: 0 8px;\n\t}\n\n\t.custom-footer,\n\t.custom-element {\n\t\tpadding: 0.1rem;\n\t}\n}\n.excalidraw .HelpDialog__key {\n\tcolor: #ffff !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
